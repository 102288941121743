<template >
  <div>



    <h3 class="text-center">Job Seekers</h3>





    <b-card>

      <b-card-header> Filter </b-card-header>

      <b-card-body>
        <b-row>
          <b-col>
            <label for="datepicker-full-width">From</label>
            <b-form-datepicker id="datepicker-full-width" v-model="fromDate" menu-class="w-100" calendar-width="100%"
              class="mb-2"></b-form-datepicker>
          </b-col>
          <b-col>
            <label for="datepicker-full">To</label>
            <b-form-datepicker id="datepicker-full" v-model="toDate" menu-class="w-100" calendar-width="100%"
              class="mb-2"></b-form-datepicker>
          </b-col>
          <div class="text-center">
            <b-button @click="filterByDate" variant="primery">filter</b-button>
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-button @click="downloadFile()">export csv</b-button>


    <p class="text-center">
      <b-spinner style="width: 5rem; height: 5rem" v-if="loading" label="Large Spinner"></b-spinner>
    </p>

    <b-table :items="companies" :fields="fields" striped responsive="sm">

      <template #cell(show_details)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
        </b-button>


      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-12">
            <h3 class="text-center">{{ row.item.fullName }}</h3>

            <b-col sm="6" class="text-sm-left">
              <p>Age :{{ row.item.age }}</p>
              <p>Years Of Experience :{{ row.item.years_of_experience }}</p>
              <p>Regional Experience :{{ row.item.regional_experience }}</p>

              <p>Gross Current Salary : {{row.item.gross_current_Salary}}</p>
              <p>Availability : {{row.item.availability}}</p>
              <p>Employment Type : {{row.item.employment_type}}</p>
              <p>Career Level : {{row.item.career_level}}</p>
              <p>Expertise Area : {{row.item.expertise_area}}</p>
              <p>Address : {{row.item.address}}</p>
              <p>Email : {{row.item.email}}</p>
              <p>Telephone : {{row.item.telephone}}</p>
              <p>Date of birth : {{row.item.Date_of_birth}}</p>
              <p>
                Education
                <ul v-for="(education,index) in row.item.Education" :key="index">
                  <li>Name Of Institution : {{education.name_of_institution}}</li>
                  <li>Location Of Institution : {{education.location_of_institution}}</li>
                  <li>Degree : {{education.degree}}</li>
                  <li>Major : {{education.major}}</li>
                </ul>
                </p>
                <p>
                  Language Proficiency
                  <ul v-for="(education,index) in row.item.language_proficiency" :key="index">
                    <li>Language : {{education.language}}</li>
                    <li>Speaking : {{education.speaking}}</li>
                    <li>Writing : {{education.writing}}</li>
                    <li>Reading : {{education.reading}}</li>
                  </ul>
                  </p>
                  <p>
                    Employment History
                    <ul v-for="(education,index) in row.item.employment_history" :key="index">
                      <li>Position Title : {{education.position_title}}</li>
                      <li>Employers Name : {{education.employers_name}}</li>
                      <li>Summary Of Position : {{education.summary_of_position}}</li>
                      <li>Date Of Employment From : {{education.date_Of_employment_from}}</li>
                      <li>Date Of Employment To : {{education.date_Of_employment_to}}</li>
                      <li>Monthly Gross Rate : {{education.monthly_gross_rate}}</li>
                    </ul>
                    </p>
                    <p>
                      Specific Consultant Services
                      <ul v-for="(education,index) in row.item.specific_consultant_services" :key="index">
                        <li>Services Performed : {{education.services_performed}}</li>
                        <li>Employers Name : {{education.employers_name}}</li>
                        <li>Level Of Effort : {{education.level_of_effort}}</li>
                        <li>Daily Rate : {{education.daily_rate}}</li>
                      </ul>
                      </p>

            </b-col>

            <b-col sm="6" class="text-sm-left">
              <p> <b>Resume</b><br> <a :href="row.item.resume">{{ row.item.resume }}</a> </p>


            </b-col>



          </b-row>



          <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
        </b-card>
      </template>
    </b-table>
  </div>
</template>
<script>
import { getDataFilterByDate, getFields, getSubModel } from '../../assets/js/service'
import exportFromJSON from "export-from-json";
import { forEach } from 'core-js/internals/enum-bug-keys';
export default {
  watch: {
    "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
  data() {
    return {
      companies: null,
      loading: false,
      fields: ['full_name', 'age', 'show_details'],
    }
  },


  mounted() {

    this.$store.commit("set", ["loading", true]);
    let token = localStorage.getItem('token');
    if (this.$route.params.id != "false" && this.$route.params.id != "null" && this.$route.params.id) {
   
      getSubModel('careers', 'jobSeekers', token, this.$route.params.id,{ value: "date", type: "DESC" }).then(resp => {
        this.companies = resp.data.map(company => {
          company.isActive = false;
          this.$store.commit("set", ["loading", false]);
          return company
        });
      })
    } else {
    
      getFields('jobSeekers', token, { type: "type", value: 'upload_only' },false,{ value: "date", type: "DESC" }).then(resp => {
        this.companies = resp.data.map(company => {
          company.isActive = false;

          return company
        });
        this.$store.commit("set", ["loading", false]);
      })
    }


  },
  methods: {
    downloadFile() {
      const data = this.companies;
      data.forEach(element => {
        delete element.id;
        delete element.type;
        delete element.isActive;
        delete element._showDetails;
      });

      console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");
      console.log(data);
      const fileName = "Job Seekers";
      const exportType = exportFromJSON.types.csv;

      if (data) exportFromJSON({ data, fileName, exportType });
    },
    filterByDate() {
      this.filterdData = true;
      let token = localStorage.getItem("token");
      getDataFilterByDate(
        "jobSeekers", this.toDate, this.fromDate,
        token
      ).then((resp) => {
        console.log(resp);
        this.companies = resp.data;
      });
    },
  },
}
</script>
<style >

</style>